import React from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import useStyles from "./styles";

export default function BreadCrumbs({ data }) {
    const classes = useStyles();

    return (
        <Breadcrumbs aria-label="breadcrumb">
            {data.slice(0, data.length - 1).map(({ href, label }) => (
                <Link to={href} className={classes.linkBreadcrumb} key={href}>
                    {label}
                </Link>
            ))}

            <Typography color="textPrimary" className={classes.textBreadcrumb}>
                {data[data.length - 1]?.label}
            </Typography>
        </Breadcrumbs>
    );
}