import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    inputsContainer: {
        display: "flex",
        flexDirection: "column",
        margin: "2% 0% 4%",
        "&>span": {
            fontWeight: '700',
            marginBottom: "1%",
        }
    },
    rootAccordion: {
        fontWeight: "700",
        width: "100%",
        boxShadow: "1px 1px 3px grey;",
        "& .MuiAccordionSummary-root": {
            background: theme.palette.background.paper,
        },
    },
    divider: {
        width: "100%",
        margin: "10px 0",
    },
}))