import React, { useEffect } from "react";
import { useStyles } from "./styles";
import TableSiniestros from "../../components/TableSiniestros";
import { createBreadcrumbData } from "../../common/constants";
import { Grid, Button } from "@material-ui/core";

const Siniestros = (props) => {
    const { setBreadcrumbs, setSectionToRender } = props;
    const classes = useStyles();

    useEffect(() => {
        setBreadcrumbs([
            createBreadcrumbData(`/#/siniestros`, `Siniestros`),
        ]);
    }, [setBreadcrumbs]);

    const goToAltaSiniestro = () => {
        window.location.assign(`/#/alta-siniestro`)
    };

    return (
        <Grid className={classes.containerGeneral}>
            <Grid className={classes.buttonContainer}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => goToAltaSiniestro()}
                >
                    Denunciar siniestro
                </Button>
            </Grid>
            <TableSiniestros
                setBreadcrumbs={setBreadcrumbs}
                setSectionToRender={setSectionToRender} />
        </Grid>
    )
};

export default Siniestros;