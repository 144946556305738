import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    headerContainer: {
        display: "flex",
        justifyContent: "space-between"
    },
    idSubtitle: {
        color: "gray",
        fontSize: "16.5px",
        marginBottom: "5px"
    },
    containerRight: {
        width: "52%",
        minHeight: "350px"
    },
    containerLeft: {
        width: "45%",
        height: "350px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    paper: {
        width: "100%",
        height: "300px",
        overflowY: "scroll",
        overflowX: "hidden",
        display: "flex",
        justifyContent: "center"
    },
    inputsContainer: {
        display: "flex",
        flexDirection: "column",
        margin: "2% 0% 0%",
        "&>span": {
            fontWeight: '700',
            marginBottom: "1%",
        }
    },
    siniestroPagadoContainer: {
        margin: "10px 0px 15px",
    },
    datesContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: "4.5%",
        "&>span": {
            fontWeight: '400',
            marginLeft: "1%"
        }
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "end",
        alignItems: "end",
        height: "40%",
        padding: "0% 3% 3% 0%",
    },
    addButton: {
        width: "40px",
        height: "40px",
        background: "#a8323d",
    },
    containerButtons: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        padding: "15px 0px 15px",
    },
    listContainer: {
        backgroundColor: theme.palette.background.paper,
        border: "3px solid #ccc",
        borderRadius: "4px",
        padding: "0px 10px 0px",
        maxHeight: 250,
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
        margin: "2% 0% 5%",
    },
    listItemsContainer: {
        borderBottom: "1px solid #ccc",
    },
    listItem: {
        "& .MuiTypography-root": {
            fontSize: "15px !important",
        },
    },
    listItemText: {
        width: "50%",
        paddingLeft: "20px",
        display: "flex",
        alignContent: "center",
    },
    ListItemContainer: {
        display: "flex",
        width: "50%",
    },
    noInfoContainer: {
        width: "100%",
        padding: "90px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        "& img": {
            marginBottom: "20px",
            width: "90px",
            height: "90px",
        },
    },
    noInfoText: {
        fontSize: "16.5px !important",
    },
    icon: {
        fontSize: "20px",
    },
    list: {
        marginBottom: theme.spacing(2),
        paddingBottom: "30px",
        maxHeight: "100%",
        width: "100%"
    },
    subheader: {
        backgroundColor: theme.palette.background.paper,
        fontSize: "14px",
    },
    rootAccordion: {
        marginBottom: "15px",
        fontWeight: "700",
        width: "100%",
        boxShadow: "1px 1px 3px grey;",
        "& .MuiAccordionSummary-root": {
            background: theme.palette.background.paper,
        },
    },
    divider: {
        width: "100%",
        margin: "20px 0",
        display: "flex",
        justifyContent: "end"
    },
    accordionContainer: {
        marginBottom: "8%"
    }
}))