import React, { useEffect, useState } from "react";
import { Grid, Button } from "@material-ui/core";
import { useStyles } from "./styles";
import { createBreadcrumbData } from "../../common/constants";
import { getTiposSiniestro, getOperaciones, altaSiniestro } from "../../service/siniestro.service";
import { Select, MenuItem } from '@mui/material';
import AutocompleteInput from "../Autocomplete";
import { isEqual } from "lodash";
import { FormHelperText } from "@material-ui/core";

function AltaSiniestro(props) {
    const { setBreadcrumbs, setSectionToRender, setDataAltaSiniestro, setDataAltaOperacion, setDataDocReq } = props;
    const classes = useStyles();
    const [helperText, setHelperText] = useState("");
    const [dataTipoSiniestros, setDataTipoSiniestros] = useState([]);
    const [dataOperaciones, setDataOperaciones] = useState([]);
    const [data, setData] = useState({
        tipoSiniestro: "",
        nOperacion: "",
    });

    useEffect(() => {
        setBreadcrumbs([
            createBreadcrumbData(`/#/alta-siniestro`, `Siniestros / Alta siniestro`),
        ]);
        setSectionToRender('altaSiniestro');
    }, [setBreadcrumbs]);

    useEffect(() => {
        fetchAllData();
    }, []);

    const fetchAllData = async () => {
        try {
            const responseSiniestros = await getTiposSiniestro();
            setDataTipoSiniestros(responseSiniestros.result);
        } catch (error) {
            console.error(error);
        }
    };

    const onAutocompleteChange = (prop) => (_, newValue, typeOperation) => {
        newValue.length >= 3 &&
            !isEqual(typeOperation, "reset") &&
            setTimeout(sendToUse(newValue), 300);
    };

    const sendToUse = async (newValue) => {
        const responseOperaciones = await getOperaciones(newValue)
        setDataOperaciones(responseOperaciones?.result);
    };

    const sendData = async (e) => {
        e.preventDefault();

        if (data.tipoSiniestro && data.nOperacion) {
            setHelperText("");
            document.getElementById("preloader").style.display = "block";
            const response = await altaSiniestro({
                numeroOperacion: data.nOperacion.numeroOperacion,
                tipoSiniestro: data.tipoSiniestro,
            });
            setDataAltaSiniestro(response.result.siniestro);
            setDataAltaOperacion(response.result.operacion);
            setDataDocReq(response.result.documentacionRequerida);
            goToGenerarSiniestro();
        } else {
            setHelperText("Complete todos los campos para proseguir");
        }
    };
    const onChange = (prop, val) => {
        setData({ ...data, [prop]: val });
    };

    const onChangeTipoSiniestro = (event) => {
        setData({ ...data, [event.target.name]: event.target.value })
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: { style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 250, } },
    };

    const goToGenerarSiniestro = () => {
        window.location.assign(`/#/generar-siniestro`)
    };

    const backSiniestros = () => {
        window.location.assign(`/#/siniestros`)
    };

    return (
        <Grid className={classes.containerGeneral}>
            <Grid item md={12} className={classes.selectContainer}>
                <span style={{ margin: "0% 0% 1%" }}>Posicion número</span>
                <AutocompleteInput
                    id="nOperacion"
                    onChange={onChange}
                    onInputChange={onAutocompleteChange("nOperacion")}
                    value={data.nOperacion}
                    options={dataOperaciones}
                    placeholder="Número de operación"
                    isRequired
                    helperText="Ingrese los 3 primeros caracteres"
                    getOptionLabel={({
                        numeroOperacion,
                        posicion,
                        tomador,
                    }) => `${numeroOperacion}, ${posicion}, ${tomador}`}
                />
            </Grid>
            <Grid item md={12} className={classes.selectContainer}>
                <span style={{ margin: "0% 0% 2%" }}>Tipo de siniestro</span>
                <Select
                    style={{ paddingTop: "0.8%", maxHeight: "40px" }}
                    labelId="demo-select-small"
                    size="small"
                    id="demo-select-small"
                    name={"tipoSiniestro"}
                    value={data.tipoSiniestro}
                    onChange={onChangeTipoSiniestro}
                    MenuProps={MenuProps}
                >
                    {dataTipoSiniestros?.map((item) => (
                        <MenuItem value={item.siniestroTipo_id} key={item.siniestroTipo_id}>{item.titulo}</MenuItem>
                    ))}
                </Select>
            </Grid>
            {helperText && (
                <FormHelperText
                    style={{ fontSize: "13px", marginTop: "10px" }}
                    error
                >
                    {helperText}
                </FormHelperText>
            )}
            <Grid className={classes.containerButton}>
                <Button
                    style={{ marginRight: "2%" }}
                    color="primary"
                    variant="outlined"
                    onClick={backSiniestros}
                >
                    Volver
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={sendData}
                >
                    Generar y continuar
                </Button>
            </Grid>
        </Grid>
    );
}


export default AltaSiniestro;





