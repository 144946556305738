import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const DragNDropZoneStyled = styled.div`
	width: 100%;
	height: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	cursor: pointer;
	border: 1px solid #ccc;
	box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
	margin: 18px 0;
	text-align: center;
`;

export const FilesListStyled = styled.ul`
	list-style: none;
	padding-left: 10px;
`;

export const FileListElementStyled = styled.li`
	margin-bottom: 4px;
	display: flex;
	align-items: center;
`;

export const ImageFileStyled = styled.img`
	margin-right: 5px;
`;

export const useStyles = makeStyles((theme) => ({
    cancelIcon: {
        marginLeft: "10px",
        cursor: "pointer",
    },
    iconDisabled: {
        cursor: "inherit",
    },
}));
