import React, { useEffect, useState } from "react";
import { Grid, Button, IconButton, Collapse } from "@material-ui/core";
import { useStyles } from "./styles";
import { createBreadcrumbData, onUploadFiles } from "../../common/constants";
import { TextField } from '@mui/material';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import StarsIcon from "@material-ui/icons/Stars";
import Dropzone from "../Dropzone";
import { putSiniestro } from "../../service/siniestro.service";
import moment from "moment";
import swal from "sweetalert2";
import InputAdornment from '@mui/material/InputAdornment';
import AccordionComponent from "../Accordion";

function GenerarSiniestro(props) {
    const { setBreadcrumbs, dataAltaSiniestro, dataAltaOperacion, setSectionToRender, sectionToRender, dataDocReq } = props;
    const classes = useStyles();
    const [expandMoreDropzone, setExpandMoreDropzone] = useState(true);
    const [files, setFiles] = useState([]);
    const [filesMaxSize, setFilesMaxSize] = useState(false);
    const [isUpdateSiniestro, setIsUpdateSiniestro] = useState(null);
    const [upLoadFilesSuccess, setUpLoadFileSuccess] = useState(null);
    const [uploadFilesWithError, setUploadFilesWithError] = useState([]);
    const [filesLenght, setFileslenght] = useState(0);
    const [data, setData] = useState({
        lugar: "",
        fechaSiniestro: "",
        montoReclamo: "",
        descripcion: "",
    });

    useEffect(() => {
        setBreadcrumbs([
            createBreadcrumbData(`/#/generar-siniestro`, `Siniestros / Alta siniestro`),
        ]);
        setSectionToRender('generarSiniestro');
    }, []);

    useEffect(() => {
        if (uploadFilesWithError.length && filesLenght > 0) {
            if (uploadFilesWithError.length === filesLenght) {
                document.getElementById("preloader").style.display = "none";
                const namesErrorFiles = uploadFilesWithError.reduce((accumulator, currentValue) => accumulator + ', ' + currentValue,);
                swal.fire({
                    title: "Intente nuevamente",
                    text: namesErrorFiles,
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                    position: 'top',
                })
            }
        } if ((isUpdateSiniestro === 'success' && filesLenght === 0) || (filesLenght > 0 && upLoadFilesSuccess === 'success' && isUpdateSiniestro === 'success' && uploadFilesWithError.length === 0)) {
            document.getElementById("preloader").style.display = "none";
            swal.fire({
                text: 'Finalizado',
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
                position: 'top',
            })
            setTimeout(() => backSiniestros(), 2000);
        } else if (isUpdateSiniestro === 'error') {
            document.getElementById("preloader").style.display = "none";
            return swal.fire({
                title: "Falló la actualización del siniestro",
                icon: 'error',
                confirmButtonText: 'Aceptar',
                position: 'top',
            })
        }
    }, [isUpdateSiniestro, upLoadFilesSuccess, uploadFilesWithError, filesLenght]);

    useEffect(() => {
        document.getElementById("preloader").style.display = "none";
        if (dataAltaSiniestro) {
            setData({
                ...data,
                fechaSiniestro: dataAltaSiniestro?.fechaSiniestro,
                lugar: dataAltaSiniestro?.lugarSiniestro,
                montoReclamo: dataAltaSiniestro?.montoReclamo,
                descripcion: dataAltaSiniestro?.descripcionHecho
            })
        }
    }, [dataAltaSiniestro]);

    const handleExpandMoreFiles = () => setExpandMoreDropzone(!expandMoreDropzone);

    const onChange = (event) => {
        setData({ ...data, [event.target.name]: event.target.value })
    };

    const sendData = async () => {
        document.getElementById("preloader").style.display = "block";
        swal.fire({
            text: 'Cargando...',
            showConfirmButton: false,
            position: 'start',
            width: "10%",
            background: 'none',
            color: "white"
        })
        onUploadFiles(setFileslenght, setUploadFilesWithError, setUpLoadFileSuccess, files, setFilesMaxSize, dataAltaSiniestro?.idSiniestro);

        const responseUpdateSiniestro = await putSiniestro({
            ...data,
            idSiniestro: dataAltaSiniestro?.idSiniestro,
        });
        if (responseUpdateSiniestro.status === "success") {
            setIsUpdateSiniestro('success');
        } else {
            setIsUpdateSiniestro('error');
        }
    };

    const backSiniestros = () => {
        window.location.assign(`/#/siniestros`)
    };

    return (
        <Grid container>
            <AccordionComponent sectionToRender={sectionToRender} dataAltaOperacion={dataAltaOperacion} />
            <Grid item md={12} className={classes.inputsContainer}>
                <span>Lugar del siniestro</span>
                <TextField
                    size="small"
                    id="outlined-basic"
                    name="lugar"
                    variant="outlined"
                    onChange={onChange}
                    value={data.lugar}
                />
            </Grid>
            <Grid item md={12} className={classes.inputsContainer}>
                <span>Fecha del siniestro</span>
                <TextField
                    type="date"
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    name="fechaSiniestro"
                    value={moment(data.fechaSiniestro).format('YYYY-MM-DD')}
                    onChange={onChange}
                    sx={{ width: '25ch' }}
                />
            </Grid>
            <Grid item md={12} className={classes.inputsContainer}>
                <span>Monto del reclamo</span>
                <TextField
                    type="number"
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    name="montoReclamo"
                    onChange={onChange}
                    value={data.montoReclamo}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                />
            </Grid>
            <Grid item md={12} className={classes.inputsContainer}>
                <span>Descripción del hecho</span>
                <TextField
                    aria-label="minimum height"
                    minRows={6}
                    onChange={onChange}
                    value={data.descripcion}
                    id="outlined-multiline-static"
                    multiline
                    name="descripcion"
                />
            </Grid>
            <Grid style={{ width: "100%" }}>
                <Grid className={classes.filesLabelContainer}>
                    <span style={{ fontWeight: "700" }}>Archivos</span>
                    <IconButton onClick={handleExpandMoreFiles}>
                        {expandMoreDropzone ? (
                            <ExpandMoreIcon />
                        ) : (
                            <ExpandLessIcon />
                        )}
                    </IconButton>
                </Grid>
                <Collapse in={!expandMoreDropzone}>
                    <Grid container className={classes.documentationList}>
                        {dataDocReq?.map((doc) => (
                            <Grid
                                item
                                key={doc.archivoTipo_id}
                                xs={12}
                                md={6}
                                className={classes.listItemDocumentation}
                            >
                                <StarsIcon className={classes.checkIcon} />{" "}
                                {doc.titulo}
                            </Grid>
                        ))}
                    </Grid>
                </Collapse>
                <Dropzone files={files} setFiles={setFiles} />
            </Grid>
            <Grid className={classes.containerButtons}>
                <Button
                    style={{ marginRight: "2%" }}
                    color="primary"
                    variant="outlined"
                    onClick={backSiniestros}
                >
                    Volver
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={sendData}
                >
                    Finalizado
                </Button>
            </Grid>
        </Grid >
    )
}

export default GenerarSiniestro;