import { BASE_URL } from "../common/constants";

export const getSiniestros = async () => {
    let JWT = localStorage.getItem('JWT');
    try {
        let response = await fetch(`${BASE_URL}/clientes/siniestros`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: JWT,
            },
        });
        response = await response.json();
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const getTiposSiniestro = async () => {
    let JWT = localStorage.getItem("JWT");
    try {
        let response = await fetch(`${BASE_URL}/clientes/tipoSiniestro`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: JWT,
            },
        });
        response = await response.json();
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const getOperaciones = async (operationNumber) => {
    let JWT = localStorage.getItem("JWT");
    try {
        let response = await fetch(`${BASE_URL}/clientes/operaciones?search=${operationNumber}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: JWT,
            },
        });
        response = await response.json();
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const getSiniestro = async (idSiniestro) => {
    let JWT = localStorage.getItem("JWT");
    try {
        let response = await fetch(`${BASE_URL}/clientes/siniestro/${idSiniestro}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: JWT,
            },
        });
        response = await response.json();
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const altaSiniestro = async (data) => {
    let JWT = localStorage.getItem("JWT");
    try {
        let response = await fetch(`${BASE_URL}/clientes/siniestro`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: JWT,
            },
            body: JSON.stringify({
                ...data
            })
        });
        response = await response.json();
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const putSiniestro = async (data) => {
    let JWT = localStorage.getItem("JWT");
    try {
        let response = await fetch(`${BASE_URL}/clientes/siniestro`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: JWT,
            },
            body: JSON.stringify({
                ...data
            })
        });
        response = await response.json();
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const getAllFiles = async (siniestroId) => {
    let JWT = localStorage.getItem("JWT");
    try {
        let response = await fetch(`${BASE_URL}/clientes/archivo?siniestroId=${siniestroId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: JWT,
            },
        });
        response = await response.json();
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const getAllFileTypes = async () => {
    let JWT = localStorage.getItem("JWT");
    try {
        let response = await fetch(`${BASE_URL}/clientes/archivoTipo`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: JWT,
            },
        });
        response = await response.json();
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const getComunicacionesBySiniestro = async (idSiniestro) => {
    let JWT = localStorage.getItem("JWT");
    try {
        let response = await fetch(`${BASE_URL}/clientes/comunicaciones?idSiniestro=${idSiniestro}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: JWT,
            },
        });
        response = await response.json();
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const getComunicacion = async (comunicacionId) => {
    let JWT = localStorage.getItem("JWT");
    try {
        let response = await fetch(`${BASE_URL}/clientes/comunicacion/${comunicacionId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: JWT,
            },
        });
        response = await response.json();
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const getStates = async () => {
    let JWT = localStorage.getItem("JWT");
    try {
        let response = await fetch(`${BASE_URL}/clientes/siniestroEstados`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: JWT,
            },
        });
        response = await response.json();
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const getMenu = async () => {
    let JWT = localStorage.getItem("JWT");
    try {
        let response = await fetch(`${BASE_URL}/clientes/menu`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: JWT,
            },
        });
        response = await response.json();
        return response;
    } catch (error) {
        console.error(error);
    }
};



