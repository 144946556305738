import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    linkBreadcrumb: {
        color: "rgba(0,0,0,.54)",
        cursor: "pointer",
        fontSize: "17px",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    textBreadcrumb: {
        fontSize: "17px !important",
    },
}));

export default useStyles;
