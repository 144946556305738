import React from "react";
import { Grid, Accordion, AccordionDetails, AccordionSummary, Divider } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useStyles } from "./styles";
import moment from "moment";

function AccordionComponent(props) {
    const { sectionToRender, dataAltaOperacion, dataOperacion } = props;
    const classes = useStyles();

    return (
        <Grid item md={12} className={classes.inputsContainer}>
            {sectionToRender === 'generarSiniestro' ?
                <strong style={{ margin: "0% 0% 2%" }}>Siniestro de op</strong> :
                <strong style={{ margin: "2% 0% 4%" }}>Propuesta: {dataOperacion?.nOperacion}</strong>
            }
            <Accordion className={classes.rootAccordion} elevation={2}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <strong>Detalles</strong>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={12} md={6} style={{ fontWeight: 400 }}>
                            <strong>Fecha de salida: </strong>
                            {sectionToRender === 'generarSiniestro' ?
                                moment(dataAltaOperacion?.fechaSalida).format("DD-MM-YYYY") :
                                moment(dataOperacion?.fechaSalida).format("DD-MM-YYYY")
                            }
                        </Grid>
                        <Grid item xs={12} md={6} style={{ fontWeight: 400 }}>
                            <strong>Consignee: </strong>
                            {sectionToRender === 'generarSiniestro' ? dataAltaOperacion?.consignee : dataOperacion?.consignee}
                        </Grid>
                        <Divider className={classes.divider} />
                        <Grid item xs={12} md={6} style={{ fontWeight: 400 }}>
                            <strong>Mercadería transportada: </strong>
                            {sectionToRender === 'generarSiniestro' ? dataAltaOperacion?.mercaderiaTransportada : dataOperacion?.mercaderiaTransportada}
                        </Grid>
                        <Grid item xs={12} md={6} style={{ fontWeight: 400 }}>
                            <strong>Shipper: </strong>
                            {sectionToRender === 'generarSiniestro' ? dataAltaOperacion?.shipper : dataOperacion?.shipper}
                        </Grid>
                        <Divider className={classes.divider} />
                        <Grid item xs={12} md={6} style={{ fontWeight: 400 }}>
                            <strong>Origen: </strong>
                            {sectionToRender === 'generarSiniestro' ? dataAltaOperacion?.origen : dataOperacion?.origen}
                        </Grid>
                        <Grid item xs={12} md={6} style={{ fontWeight: 400 }}>
                            <strong>Destino: </strong>
                            {sectionToRender === 'generarSiniestro' ? dataAltaOperacion?.destino : dataOperacion?.destino}
                        </Grid>
                        <Divider className={classes.divider} />
                        <Grid item xs={12} md={6} style={{ fontWeight: 400 }}>
                            <strong>Suma asegurada: </strong>
                            {sectionToRender === 'generarSiniestro' ?
                                (dataAltaOperacion?.sumaAsegurada !== undefined ? `$ ${dataAltaOperacion?.sumaAsegurada}` : 0) :
                                (dataOperacion?.sat !== undefined ? `$ ${dataOperacion?.sat}` : 0)
                            }
                        </Grid>
                        <Grid item xs={12} md={6} style={{ fontWeight: 400 }}>
                            <strong>Tipo de riesgo: </strong>
                            {sectionToRender === 'generarSiniestro' ? dataAltaOperacion?.tipoRiesgo : dataOperacion?.tipoRiesgo}
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}

export default AccordionComponent;