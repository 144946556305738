import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    divider: {
        width: "100%",
        margin: "10px 0",
    },
    containerButton: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: "10%",
    },
}))