import React from "react";
import { useStyles } from "./styles";

function Iframe() {
    const classes = useStyles();
    let SIWCARGO_URL = sessionStorage.getItem("SIWCARGO_URL");
    return (
        <iframe className={classes.iframe} src={`${SIWCARGO_URL}/app/acceso/cliente/cliente`}></iframe>
    );
}

export default Iframe;