import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import { createBreadcrumbData } from "../../common/constants";
import { Grid, Button, Divider } from "@material-ui/core";
import { getComunicacion } from "../../service/siniestro.service"
import moment from "moment";

const VisualizarComunicacion = (props) => {
    const { setBreadcrumbs } = props;
    const classes = useStyles();
    const idSiniestro = sessionStorage.getItem('idSiniestro');
    const [comunication, setComunication] = useState();

    useEffect(() => {
        fetchAllData()
        setBreadcrumbs([
            createBreadcrumbData(`/siniestros`, `Siniestros / Ver Siniestro / Ver Comunicación`),
        ]);
    }, [setBreadcrumbs]);

    const fetchAllData = async () => {
        try {
            const comunicacionId = new URLSearchParams(window.location.search).get("idComunicacion");
            const responseComunication = await getComunicacion(comunicacionId);
            setComunication(responseComunication.result);
        } catch (error) {
            console.error(error);
        }
    };

    const backToSiniestro = () => {
        window.location.assign(`/?id=${idSiniestro}#/siniestros/ver-siniestros`)
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <strong>Tipo de comunicación: </strong>
                {comunication?.tipoComunicacionTitulo}
            </Grid>
            <Divider className={classes.divider} />
            <Grid item xs={12}>
                <strong>Asunto de la comunicación: </strong>
                {comunication?.asunto}
            </Grid>
            <Divider className={classes.divider} />
            <Grid item xs={12}>
                <strong>Fecha de la comunicación: </strong>
                {moment(comunication?.comunicacionFecha).format("DD-MM-YYYY")}
            </Grid>
            <Divider className={classes.divider} />
            <Grid item xs={12}>
                <strong>Contacto: </strong>
                {comunication?.contacto}
            </Grid>
            <Divider className={classes.divider} />
            <Grid item xs={12}>
                <strong>Resumen: </strong>
                {comunication?.resumen}
            </Grid>
            <Divider className={classes.divider} />
            {comunication?.tipoComunicacionTitulo !== "e-mail" ?
                <Grid item xs={12}>
                    <strong>Tipo de comunicador: </strong>
                    {comunication?.tipoComunicador}
                </Grid>
                : ""}
            <Grid className={classes.containerButton}>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={backToSiniestro}
                >
                    Volver
                </Button>
            </Grid>
        </Grid>
    )
}

export default VisualizarComunicacion;