import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import MaterialTable from "material-table";
import { Grid } from "@material-ui/core";
import { getSiniestros } from "../../service/siniestro.service";
import Search from '@material-ui/icons/Search';
import { Oval } from 'react-loader-spinner'
import * as XLSX from 'xlsx';
import { FcDownload } from "react-icons/fc";

const TableSiniestros = (props) => {
    const { setSectionToRender } = props;
    const classes = useStyles();
    const [dataAllSiniestros, setDataAllSiniestros] = useState([]);
    const [isWithClosedSiniestros, setIsWithClosedSiniestros] = useState(false);
    const [isLoading, setIsLoading] = useState(null);

    useEffect(() => {
        fetchAllSiniestros(isWithClosedSiniestros)
    }, [isWithClosedSiniestros]);

    useEffect(() => {
        setSectionToRender('inicio');
    }, []);

    const fetchAllSiniestros = async (withClosedSiniestros) => {
        try {
            setIsLoading('waiting')
            let response = await getSiniestros();
            if (withClosedSiniestros === false) {
                response.result.siniestros = response.result.siniestros.filter(item => item.estado !== "PAGADO" && item.estado !== "DADO DE BAJA" && item.estado !== "RECHAZADO")
            }
            setDataAllSiniestros(response?.result?.siniestros);
            setIsLoading('ready')
        } catch (error) {
            console.error(error);
        }
    };

    const columnas = [
        { title: "Número Siniestro", field: "numero", sorting: false, headerStyle: { fontSize: "15px" } },
        { title: "Número Operación", field: "posicion", sorting: false, headerStyle: { fontSize: "15px" } },
        { title: "ID", field: "siniestro_id" },
        { title: "Estado", field: "estado" },
    ];

    const searchSiniestro = (siniestro_id) => {
        window.location.assign(`/?id=${siniestro_id}#/siniestros/ver-siniestros`)
    };

    function exportToExcel(data, fileName) {
        const filteredData = data.map(row => {
            const filteredRow = {};
            Object.keys(row).forEach(key => {
                if (key !== 'tableData') {
                    filteredRow[key] = row[key];
                }
            });
            return filteredRow;
        });
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Siniestros");
        XLSX.writeFile(workbook, fileName);
    };

    return (
        <Grid>
            <Grid className={classes.checkboxContainer}>
                <Grid className={classes.guideText}>
                    {!isWithClosedSiniestros
                        ? <h5>Ver todos los siniestros</h5>
                        : <h5>Ver siniestros activos</h5>}
                </Grid>
                <input
                    style={{ transform: "scale(1.5)" }}
                    type="checkbox"
                    checked={isWithClosedSiniestros}
                    onChange={(e) => setIsWithClosedSiniestros(e.target.checked)}
                ></input>
            </Grid>
            {isLoading === 'waiting' ?
                <Grid className={classes.ovalContainer}>
                    <Oval
                        height={80}
                        width={80}
                        color="#1467a1"
                        wrapperStyle={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%"
                        }}
                        secondaryColor="#1467a1"
                    />
                </Grid> :
                <Grid>
                    <Grid style={{ paddingBottom: "0.5%" }}>
                        <h4>{!isWithClosedSiniestros ? "Siniestros Activos" : "Siniestros Activos e Inactivos"}</h4>
                    </Grid>
                    <MaterialTable
                        columns={columnas}
                        data={dataAllSiniestros}
                        title={""}
                        icons={{
                            Search: () => <Search style={{ fontSize: "18px" }} />,
                            Filter: 'none'
                        }}
                        actions={[
                            {
                                icon: "search",
                                iconProps: { style: { fontSize: "20px", color: "blue" } },
                                tooltip: "Visualizar",
                                onClick: (_, rowData) => { searchSiniestro(rowData.siniestro_id) },
                            },
                            {
                                icon: ({ color }) => <FcDownload style={{ color }} />,
                                tooltip: 'Descargar Excel',
                                isFreeAction: true,
                                onClick: () => exportToExcel(dataAllSiniestros, 'Siniestros.xlsx')
                            }
                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            filtering: true,
                            headerStyle: { textAlign: "start" },
                            cellStyle: { textAlign: "left" },
                            paginationType: "stepped",
                            columnsButton: true,
                        }}
                    />
                </Grid>}
        </Grid>
    )
};

export default TableSiniestros;


