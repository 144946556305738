import { HashRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import React, { useState } from "react";
import Navbar from "./components/NavBar";
import Grid from "@material-ui/core/Grid";
import Siniestros from "./views/Siniestros";
import AltaSiniestro from "./components/AltaSiniestro";
import VisualizarSiniestro from "./components/VisualizarSiniestro";
import GenerarSiniestro from "./components/GenerarSiniestro";
import VisualizarComunicacion from "../src/components/VisualizarComunicacion";
import Loader from "../src/components/Loader"
import Iframe from "../src/components/Iframe"

function App() {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [sectionToRender, setSectionToRender] = useState('inicio');
  const [dataAltaSiniestro, setDataAltaSiniestro] = useState(null);
  const [dataAltaOperacion, setDataAltaOperacion] = useState(null);
  const [dataDocReq, setDataDocReq] = useState(null);

  return (
    <React.Fragment>
      <Iframe />
      <Loader />
      <HashRouter>
        <Navbar breadcrumbs={breadcrumbs} />
        <Grid
          container
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingBottom: "20px",
          }}
        >
          <Grid item xs={12} md={9}>
            <Routes>
              <Route exact path="/siniestros" element={<Siniestros setBreadcrumbs={setBreadcrumbs} setSectionToRender={setSectionToRender} />} />
              <Route exact path="/alta-siniestro" element={<AltaSiniestro setBreadcrumbs={setBreadcrumbs}
                setDataAltaSiniestro={setDataAltaSiniestro}
                setDataAltaOperacion={setDataAltaOperacion}
                setDataDocReq={setDataDocReq}
                setSectionToRender={setSectionToRender}
              />} />
              <Route exact path="/generar-siniestro" element={<GenerarSiniestro setBreadcrumbs={setBreadcrumbs}
                dataAltaSiniestro={dataAltaSiniestro}
                dataAltaOperacion={dataAltaOperacion}
                dataDocReq={dataDocReq}
                sectionToRender={sectionToRender}
                setSectionToRender={setSectionToRender}
              />} />
              <Route exact path="/siniestros/ver-siniestros" element={<VisualizarSiniestro setBreadcrumbs={setBreadcrumbs} />} />
              <Route exact path="/siniestros/ver-siniestros/ver-comunicacion" element={<VisualizarComunicacion setBreadcrumbs={setBreadcrumbs} />} />
            </Routes>
          </Grid>
        </Grid>
      </HashRouter>
    </React.Fragment>
  );
};

export default App;

