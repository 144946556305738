import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles(() => ({
    checkboxContainer: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0% 0% 2%",
    },
    ovalContainer: {
        height: "75vh",
        display: "flex",
        justifyContent: "center"
    },
    guideText: {
        paddingRight: "2%"
    }
}));