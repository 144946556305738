import { forEach, isEmpty } from "lodash";

export const createBreadcrumbData = (href, label) => ({
    href,
    label,
});

const getURL = () => {
    switch (window.location.hostname) {
        case "siwcargo.local":
            return "http://localhost:7000";
        case "localhost":
            return "http://localhost:7000";
        case "siniestros.apptest.siwcargo.com":
            return "https://siniestros.api.testing.siwcargo.com";
        default:
            return "https://siniestros.api.prod.siwcargo.com";
    }
};

export const BASE_URL = getURL();
localStorage.setItem("BASE_URL", BASE_URL);

export const siwcargoURL = () => {
    switch (window.location.hostname) {
        case "siwcargo.local":
        case "localhost":
            return "http://siwcargo.local";
        case "siniestros.apptest.siwcargo.com":
            return "https://testing.siwcargo.com";
        default:
            return "https://sistema.siwcargo.com";
    }
};

sessionStorage.setItem("SIWCARGO_URL", siwcargoURL());

//método
export const __result__ = (pStatus, pMessage) => {
    return { status: pStatus, result: pMessage };
};

// método
// PHP date() in JavaScript
export const date = (format, timestamp) => {
    let jsdate, f;
    const txtWords = [
        "Sun",
        "Mon",
        "Tues",
        "Wednes",
        "Thurs",
        "Fri",
        "Satur",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const formatChr = /\\?(.?)/gi;
    const formatChrCb = function (t, s) {
        return f[t] ? f[t]() : s;
    };
    const _pad = function (n, c) {
        n = String(n);
        while (n.length < c) {
            n = "0" + n;
        }
        return n;
    };
    f = {
        // Day
        d: function () {
            // Day of month w/leading 0; 01..31
            return _pad(f.j(), 2);
        },
        D: function () {
            // Shorthand day name; Mon...Sun
            return f.l().slice(0, 3);
        },
        j: function () {
            // Day of month; 1..31
            return jsdate.getDate();
        },
        l: function () {
            // Full day name; Monday...Sunday
            return txtWords[f.w()] + "day";
        },
        N: function () {
            // ISO-8601 day of week; 1[Mon]..7[Sun]
            return f.w() || 7;
        },
        S: function () {
            // Ordinal suffix for day of month; st, nd, rd, th
            const j = f.j();
            let i = j % 10;
            if (i <= 3 && parseInt((j % 100) / 10, 10) === 1) {
                i = 0;
            }
            return ["st", "nd", "rd"][i - 1] || "th";
        },
        w: function () {
            // Day of week; 0[Sun]..6[Sat]
            return jsdate.getDay();
        },
        z: function () {
            // Day of year; 0..365
            const a = new Date(f.Y(), f.n() - 1, f.j());
            const b = new Date(f.Y(), 0, 1);
            return Math.round((a - b) / 864e5);
        },
        // Week
        W: function () {
            // ISO-8601 week number
            const a = new Date(f.Y(), f.n() - 1, f.j() - f.N() + 3);
            const b = new Date(a.getFullYear(), 0, 4);
            return _pad(1 + Math.round((a - b) / 864e5 / 7), 2);
        },
        // Month
        F: function () {
            // Full month name; January...December
            return txtWords[6 + f.n()];
        },
        m: function () {
            // Month w/leading 0; 01...12
            return _pad(f.n(), 2);
        },
        M: function () {
            // Shorthand month name; Jan...Dec
            return f.F().slice(0, 3);
        },
        n: function () {
            // Month; 1...12
            return jsdate.getMonth() + 1;
        },
        t: function () {
            // Days in month; 28...31
            return new Date(f.Y(), f.n(), 0).getDate();
        },
        // Year
        L: function () {
            // Is leap year?; 0 or 1
            const j = f.Y();
            return ((j % 4 === 0) & (j % 100 !== 0)) | (j % 400 === 0);
        },
        o: function () {
            // ISO-8601 year
            const n = f.n();
            const W = f.W();
            const Y = f.Y();
            return Y + (n === 12 && W < 9 ? 1 : n === 1 && W > 9 ? -1 : 0);
        },
        Y: function () {
            // Full year; e.g. 1980...2010
            return jsdate.getFullYear();
        },
        y: function () {
            // Last two digits of year; 00...99
            return f.Y().toString().slice(-2);
        },
        // Time
        a: function () {
            // am or pm
            return jsdate.getHours() > 11 ? "pm" : "am";
        },
        A: function () {
            // AM or PM
            return f.a().toUpperCase();
        },
        B: function () {
            // Swatch Internet time; 000..999
            const H = jsdate.getUTCHours() * 36e2;
            // Hours
            const i = jsdate.getUTCMinutes() * 60;
            // Minutes
            // Seconds
            const s = jsdate.getUTCSeconds();
            return _pad(Math.floor((H + i + s + 36e2) / 86.4) % 1e3, 3);
        },
        g: function () {
            // 12-Hours; 1..12
            return f.G() % 12 || 12;
        },
        G: function () {
            // 24-Hours; 0..23
            return jsdate.getHours();
        },
        h: function () {
            // 12-Hours w/leading 0; 01..12
            return _pad(f.g(), 2);
        },
        H: function () {
            // 24-Hours w/leading 0; 00..23
            return _pad(f.G(), 2);
        },
        i: function () {
            // Minutes w/leading 0; 00..59
            return _pad(jsdate.getMinutes(), 2);
        },
        s: function () {
            // Seconds w/leading 0; 00..59
            return _pad(jsdate.getSeconds(), 2);
        },
        u: function () {
            // Microseconds; 000000-999000
            return _pad(jsdate.getMilliseconds() * 1000, 6);
        },

        // Timezone
        e: function () {
            const msg =
                "Not supported (see source code of date() for timezone on how to add support)";
            throw new Error(msg);
        },
        I: function () {
            const a = new Date(f.Y(), 0);
            // Jan 1
            const c = Date.UTC(f.Y(), 0);
            // Jan 1 UTC
            const b = new Date(f.Y(), 6);
            // Jul 1
            // Jul 1 UTC
            const d = Date.UTC(f.Y(), 6);
            return a - c !== b - d ? 1 : 0;
        },
        O: function () {
            // Difference to GMT in hour format; e.g. +0200
            const tzo = jsdate.getTimezoneOffset();
            const a = Math.abs(tzo);
            return (
                (tzo > 0 ? "-" : "+") +
                _pad(Math.floor(a / 60) * 100 + (a % 60), 4)
            );
        },
        P: function () {
            // Difference to GMT w/colon; e.g. +02:00
            const O = f.O();
            return O.substr(0, 3) + ":" + O.substr(3, 2);
        },
        T: function () {
            return "UTC";
        },
        Z: function () {
            // Timezone offset in seconds (-43200...50400)
            return -jsdate.getTimezoneOffset() * 60;
        },
        // Full Date/Time
        c: function () {
            // ISO-8601 date.
            return "Y-m-d\\TH:i:sP".replace(formatChr, formatChrCb);
        },
        r: function () {
            // RFC 2822
            return "D, d M Y H:i:s O".replace(formatChr, formatChrCb);
        },
        U: function () {
            // Seconds since UNIX epoch
            return (jsdate / 1000) | 0;
        },
    };

    const _date = function (format, timestamp) {
        jsdate =
            timestamp === undefined
                ? new Date() // Not provided
                : timestamp instanceof Date
                    ? new Date(timestamp) // JS Date()
                    : new Date(timestamp * 1000); // UNIX timestamp (auto-convert to int)
        return format.replace(formatChr, formatChrCb);
    };

    return _date(format, timestamp);
};

export const getBase64 = (file) => {
    return new Promise((resolve) => {
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load somthing...
        reader.onload = () => {
            // Make a fileInfo Object
            baseURL = reader.result;
            resolve(baseURL);
        };
    });
};

//para subir archivos
export const CANTIDAD_ARCHIVOS = 2;

export const onUploadFiles = (setFileslenght, setUploadFilesWithError, setUpLoadFileSuccess, files, setFilesMaxSize, idSiniestro) => {

    const filesMaxSize = files.filter((file) => file.size > 15000000);
    if (!isEmpty(filesMaxSize)) {
        setFilesMaxSize(filesMaxSize.map((file) => file.name));
    } else {
        const filesReadyToPost = [];
        forEach(files, (file) => {
            getBase64(file).then((base64) => {
                filesReadyToPost.push({
                    idEntidad: Number(idSiniestro),
                    nombre: file.name,
                    archivo: base64,
                    entidad: "siniestro",
                })
                setFileslenght(filesReadyToPost.length);
                if (files.length === filesReadyToPost.length) {
                    fileUpload(true, 0, filesReadyToPost, setUploadFilesWithError, setUpLoadFileSuccess)
                }
            });
        });
    }
};

const fileUpload = (iniciaSubida, indiceSubida, archivos, setUploadFilesWithError, setUpLoadFileSuccess) => {
    if (iniciaSubida) {
        for (let i = 0; i < archivos?.length; i++) {
            if (i === CANTIDAD_ARCHIVOS) {
                break
            }
            sendFiles({ data: archivos[i], indiceSubida: i + CANTIDAD_ARCHIVOS, archivos: archivos }, setUploadFilesWithError, setUpLoadFileSuccess)
        }
    } else {
        if (indiceSubida < archivos?.length) {
            sendFiles({ data: archivos[indiceSubida], indiceSubida: indiceSubida + CANTIDAD_ARCHIVOS, archivos: archivos }, setUploadFilesWithError, setUpLoadFileSuccess)
        }
        else if
            (indiceSubida >= archivos?.length) {
            setUpLoadFileSuccess('success');
        }
    }
};

export const sendFiles = async (data, setUploadFilesWithError, setUpLoadFileSuccess) => {
    let JWT = localStorage.getItem("JWT");
    try {
        fetch(`${BASE_URL}/clientes/archivo`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: JWT,
            },
            body: JSON.stringify(data.data)
        })
            .then(async (response) => {
                response = await response.json();
                if (response.status === 'error') {
                    setUploadFilesWithError((uploadFilesWithError) => ([...uploadFilesWithError, data.data.nombre]));
                }
                fileUpload(false, data.indiceSubida, data.archivos, setUploadFilesWithError, setUpLoadFileSuccess)

            })
            .catch(function (error) {
                console.log(error);
                setUploadFilesWithError((uploadFilesWithError) => ([...uploadFilesWithError, data.data.nombre]));
                fileUpload(false, data.indiceSubida, data.archivos, setUploadFilesWithError, setUpLoadFileSuccess)
            });
    } catch (error) {
        console.error(error);
    }
};

export const sliceText = (text, numberOfChars) => {
    const suspensivePoints = text?.length > numberOfChars ? "..." : "";
    return text?.slice(0, numberOfChars) + suspensivePoints;
};








