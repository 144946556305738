import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    containerGeneral: {
        height: "100%",
    },
    containerButton: {
        width: "100%",
        height: "100%",
        paddingBottom: "2%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
    },
    selectContainer: {
        display: "flex",
        flexDirection: "column",
        margin: "2% 0% 0%",
        "&>span": {
            fontWeight: '700',
            marginBottom: "1%",
        }
    },
}));