import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { Grid, IconButton, Button } from "@material-ui/core";
import logo from "../../assets/logoSiw.png";
import BreadCrumbs from "../BreadCrumbs";
import { Menu, MenuItem } from '@mui/material';
import { BiCaretDown } from "react-icons/bi";
import { getMenu } from "../../service/siniestro.service";

function NavBar(props) {
    const { breadcrumbs } = props;
    const classes = useStyles();
    const [sideMenu, setSideMenu] = useState(false);
    const [navbarItems, setNavbarItems] = useState({});
    const [navbarItemUsuario, setNavbarItemUsuario] = useState([]);
    const [sendProposal, setSendProposal] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [anchorElPropuestas, setAnchorElPropuestas] = React.useState(null);
    const openPropuestas = Boolean(anchorElPropuestas);
    const [anchorElSeguridad, setAnchorElSeguridad] = React.useState(null);
    const openSeguridad = Boolean(anchorElSeguridad);
    const [anchorElUsuario, setAnchorElUsuarios] = React.useState(null);
    const openUsuario = Boolean(anchorElUsuario);
    const toggleDrawer = () => setSideMenu(!sideMenu);
    const rastreo = localStorage.getItem("RASTREO");
    const api = localStorage.getItem("API");
    const name = localStorage.getItem("NAME");
    const lastname = localStorage.getItem("LASTNAME");
    const chatbot = localStorage.getItem("CHATBOT");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const responseAllNavbarItems = await getMenu();
        setNavbarItems(responseAllNavbarItems.result);
        if (api !== 1) {
            const withoutApi = [...responseAllNavbarItems.result.usuario];
            withoutApi.splice(2, 1);
            setNavbarItemUsuario(withoutApi);
        } else {
            setNavbarItemUsuario(responseAllNavbarItems.result.usuario);
        };
        if (chatbot !== 1) {
            const withoutAutomaticApproval = [...responseAllNavbarItems.result.enviarPropuestas];
            withoutAutomaticApproval.splice(0, 1);
            setSendProposal(withoutAutomaticApproval);
        } else {
            setSendProposal(responseAllNavbarItems.result.enviarPropuestas);
        };
    };

    const handleClickSiniestros = (event) => { setAnchorEl(event.currentTarget) };
    const handleCloseSiniestros = (e) => {
        const path = e.target.id
        window.location.assign(`${path}`)
        setAnchorEl(null);
    };
    const handleClickPropuestas = (event) => { setAnchorElPropuestas(event.currentTarget) };
    const handleClosePropuestas = (e) => {
        const path = e.target.id
        window.location.assign(`${path}`)
        setAnchorElPropuestas(null);
    };
    const handleClickSeguridad = (event) => { setAnchorElSeguridad(event.currentTarget) };
    const handleCloseSeguridad = (e) => {
        const path = e.target.id
        window.location.assign(`${path}`)
        setAnchorElSeguridad(null);
    };
    const handleClickUsuario = (event) => { setAnchorElUsuarios(event.currentTarget) };
    const handleCloseUsuario = (e) => {
        const path = e.target.id
        window.location.assign(`${path}`)
        setAnchorElUsuarios(null);
    };
    const handleOnClick = (link) => {
        window.location.assign(`${link}`)
    };

    const menuList = (
        <Grid className={classes.list}>
            <Button
                id="basic-button"
                style={{ color: "black", textTransform: 'none' }}
                onClick={handleClickPropuestas}
            >Enviar propuesta<BiCaretDown /></Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorElPropuestas}
                open={openPropuestas}
                onClose={handleClosePropuestas}
            >
                {sendProposal?.map((item) => (
                    <MenuItem onClick={(e) => handleClosePropuestas(e)} id={item.link}>{item.titulo}</MenuItem>
                ))}
            </Menu>
            {navbarItems.estadoCuentaCorriente?.map((item) => (
                <Button
                    id="basic-button"
                    style={{ color: "black", textTransform: 'none' }}
                    onClick={() => { handleOnClick(item.link) }}
                >Estado Cuenta Corriente</Button>
            ))}
            {navbarItems.verPropuestasAnteriores?.map((item) => (
                <Button
                    id="basic-button"
                    style={{ color: "black", textTransform: 'none' }}
                    onClick={() => { handleOnClick(item.link) }}
                >Ver Propuestas Anteriores</Button>
            ))}
            {rastreo === 1 ?
                <>
                    <Button
                        id="basic-button"
                        style={{ color: "black", textTransform: 'none' }}
                        onClick={handleClickSeguridad}
                    >Seguridad (Asist cargo)<BiCaretDown /></Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorElSeguridad}
                        open={openSeguridad}
                        onClose={handleCloseSeguridad}
                    >
                        {navbarItems.seguridad?.map((item) => (
                            <MenuItem onClick={(e) => handleCloseSeguridad(e)} id={item.link}>{item.titulo}</MenuItem>
                        ))}
                    </Menu>
                </> : ""}
            <Button
                id="basic-button"
                style={{ color: "black", textTransform: 'none' }}
                onClick={handleClickSiniestros}
            >Siniestros<BiCaretDown /></Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseSiniestros}
            >
                <MenuItem onClick={(e) => handleCloseSiniestros(e)} id={`/#/siniestros`}>Siniestros</MenuItem>
                <MenuItem onClick={(e) => handleCloseSiniestros(e)} id={`/#/alta-siniestro`}>Denunciar Siniestro</MenuItem>
            </Menu>
            <Button
                id="basic-button"
                style={{ color: "black", textTransform: 'none' }}
                onClick={handleClickUsuario}
            >{name} {lastname}<BiCaretDown /></Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorElUsuario}
                open={openUsuario}
                onClose={handleCloseUsuario}
            >
                {navbarItemUsuario?.map((item) => (
                    <MenuItem onClick={(e) => handleCloseUsuario(e)} id={item.link}>{item.titulo}</MenuItem>
                ))}
            </Menu>
        </Grid>
    );

    return (
        <Grid className={classes.container}>
            <Grid container className={classes.navBarContainer}>
                <Grid className={classes.navBar}>
                    <a href="http://sistema.siwcargo.com/">
                        <img src={logo} alt="SIW" className={classes.logo} />
                    </a>
                    <IconButton
                        className={classes.mobileDisplayTrue}
                        onClick={toggleDrawer}
                    >
                    </IconButton>
                    <Grid className={classes.mobileDisplayFalse}>{menuList}</Grid>
                </Grid>
            </Grid>
            <Grid className={classes.breadcrumb}>
                <BreadCrumbs data={breadcrumbs || []} />
            </Grid>
        </Grid>
    )
};

export default NavBar;