import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    inputsContainer: {
        display: "flex",
        flexDirection: "column",
        margin: "2% 0% 0%",
        "&>span": {
            fontWeight: '700',
            marginBottom: "0.5%",
        }
    },
    filesLabelContainer: {
        display: "flex",
        alignItems: "center",
        marginTop: "5%"
    },
    listItemDocumentation: {
        display: "flex",
        alignItems: "center",
        marginBottom: "15px",
    },
    documentationList: {
        listStyle: "none",
        padding: "20px",
    },
    checkIcon: {
        marginRight: "8px",
        color: "green",
    },
    containerButtons: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        padding: "15px 0px 15px",
    },
    rootAccordion: {
        fontWeight: "700",
        width: "100%",
        boxShadow: "1px 1px 3px grey;",
        margin: "10px 0",
        "& .MuiAccordionSummary-root": {
            background: theme.palette.background.paper,
        },
    },
    divider: {
        width: "100%",
        margin: "10px 0",
    },
}));