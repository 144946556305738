import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles(() => ({
    containerGeneral: {
        display: "flex",
        flexDirection: "column",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "2% 0% 2%"
    }
}));