import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import { Grid, Button, IconButton, Typography, List, ListItem, ListItemText, ListSubheader, ListItemAvatar, Divider } from "@material-ui/core";
import { TextField } from '@mui/material';
import { createBreadcrumbData, sliceText, onUploadFiles } from "../../common/constants";
import Dropzone from '../Dropzone';
import Paper from "@material-ui/core/Paper";
import { getTiposSiniestro, getSiniestro, getAllFiles, getAllFileTypes, getComunicacionesBySiniestro, putSiniestro, getStates } from "../../service/siniestro.service";
import moment from "moment";
import { isEmpty, isEqual } from "lodash";
import notInfoFace from "../../assets/noInfoFace.svg";
import VisibilityIcon from "@material-ui/icons/Visibility";
import swal from "sweetalert2";
import Avatar from "@material-ui/core/Avatar";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import ChatIcon from "@material-ui/icons/Chat";
import InputAdornment from '@mui/material/InputAdornment';
import AccordionComponent from "../Accordion";
import { Oval } from 'react-loader-spinner'

const VisualizarSiniestro = (props) => {
    const { setBreadcrumbs } = props;
    const classes = useStyles();
    let idSiniestro = new URLSearchParams(window.location.search).get("id");
    const [dataTipoSiniestros, setDataTipoSiniestros] = useState([]);
    const [dataSiniestro, setDataSiniestro] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileTypes, setFileTypes] = useState([]);
    const [files, setFiles] = useState([]);
    const [inputDisabled, setInputDisabled] = useState({});
    const [filesMaxSize, setFilesMaxSize] = useState(false);
    const [upLoadFilesSuccess, setUpLoadFileSuccess] = useState('start');
    const [uploadFilesWithError, setUploadFilesWithError] = useState([]);
    const [isUpdateSiniestro, setIsUpdateSiniestro] = useState(null);
    const [allComunicaciones, setAllComunicaciones] = useState();
    const [dataOperacion, setDataOperacion] = useState([]);
    const [allStates, setAllStates] = useState([]);
    const [filesLenght, setFileslenght] = useState(0);
    const [isLoading, setIsLoading] = useState(null);
    const [data, setData] = useState({
        tipoSiniestro: "",
        fechaSiniestro: "",
        fechaDenuncia: "",
        lugar: "",
        montoReclamo: "",
        transportista: "",
        contratador: "",
        datosInspeccion: "",
        descripcion: "",
        siniestroEstado_id: "",
    });

    useEffect(() => {
        setBreadcrumbs([
            createBreadcrumbData(`/siniestros`, `Siniestros / Ver Siniestro`),
        ]);
    }, [setBreadcrumbs]);

    useEffect(() => {
        document.getElementById("preloader").style.display = "block";
        fetchAllData();
    }, []);

    useEffect(() => {
        if (dataSiniestro) {
            setData({
                ...data,
                fechaSiniestro: dataSiniestro?.fechaSiniestro,
                fechaDenuncia: dataSiniestro?.fechaDenuncia,
                montoReclamo: dataSiniestro?.montoReclamo,
                lugar: dataSiniestro?.lugar,
                transportista: dataSiniestro?.transportista,
                contratador: dataSiniestro?.contratador,
                datosInspeccion: dataSiniestro?.datosInspeccion,
                descripcion: dataSiniestro?.descripcion,
            })
        }
    }, [dataSiniestro]);

    useEffect(() => {
        if (uploadFilesWithError.length && filesLenght > 0) {
            if (uploadFilesWithError.length === filesLenght) {
                document.getElementById("preloader").style.display = "none";
                const namesErrorFiles = uploadFilesWithError.reduce((accumulator, currentValue) => accumulator + ', ' + currentValue,);
                swal.fire({
                    title: "Intente nuevamente",
                    text: namesErrorFiles,
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                    position: 'top',
                })
            }
        } if ((isUpdateSiniestro === 'success' && filesLenght === 0) || (filesLenght > 0 && upLoadFilesSuccess === 'success' && isUpdateSiniestro === 'success' && uploadFilesWithError.length === 0)) {
            document.getElementById("preloader").style.display = "none";
            swal.fire({
                text: 'Finalizado',
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
                position: 'top',
            })
            setTimeout(() => window.location.assign(`/#/siniestros`), 2000);
        } else if (isUpdateSiniestro === 'error') {
            document.getElementById("preloader").style.display = "none";
            swal.fire({
                title: "Falló la actualización del siniestro",
                icon: 'error',
                confirmButtonText: 'Aceptar',
                position: 'top',
            })
        }
    }, [isUpdateSiniestro, upLoadFilesSuccess, uploadFilesWithError, filesLenght]);

    const fetchAllData = async () => {
        try {
            setIsLoading('waiting');
            const responseSiniestro = await getSiniestro(idSiniestro);
            setDataSiniestro(responseSiniestro.result.siniestro);
            setDataOperacion(responseSiniestro.result.operacion);
            const responseTiposSiniestro = await getTiposSiniestro();
            setDataTipoSiniestros(responseTiposSiniestro.result);
            const responseStates = await getStates();
            setAllStates(responseStates?.result)
            document.getElementById("preloader").style.display = "none";
            const responseAllFiles = await getAllFiles(idSiniestro);
            setUploadedFiles(responseAllFiles.result)
            const responseAllFileTypes = await getAllFileTypes();
            setFileTypes(responseAllFileTypes.result)
            const responseAllComunicaciones = await getComunicacionesBySiniestro(idSiniestro);
            setAllComunicaciones(responseAllComunicaciones.result)
            setIsLoading('empty');
        } catch (error) {
            console.error(error);
        }
    };
    const siniestroTipoSelected = dataTipoSiniestros?.find((tipo) => dataSiniestro?.idTipoSiniestro === tipo?.siniestroTipo_id);
    const stateSiniestro = allStates?.find((state) => dataSiniestro?.siniestroEstado_id === state?.siniestroEstado_id);
    const comunicacionCliente = allComunicaciones?.filter((comunicacion) => comunicacion.entidad_comunicacion === "cliente")
    let comunicationFormattedAndSorted = comunicacionCliente?.map((item) => ({
        ...item,
        fecha: new Date(item.fecha),
    }))
        .sort(function (a, b) {
            return (new Date(b.fecha) - new Date(a.fecha));
        });

    const nothingHere = () => (
        <Grid className={classes.noInfoContainer}>
            <>
                <img src={notInfoFace} alt="No info" />
                <Typography variant="body1" className={classes.noInfoText}>
                    Nada por aqui
                </Typography>
            </>
        </Grid>
    );

    const subheaderDateComparison = (date, index) => {
        if (isEqual(index, 0) || !isEqual(comunicationFormattedAndSorted[index - 1].fecha.toLocaleDateString(), date.toLocaleDateString())) {
            return (
                <ListSubheader className={classes.subheader}>
                    {date.toLocaleDateString()}
                </ListSubheader>
            )
        }
    };

    const visualizeComunicacion = (comunicacionId) => {
        window.location.assign(`/?idComunicacion=${comunicacionId}#/siniestros/ver-siniestros/ver-comunicacion`)
    };

    const handleCommunicationType = (communicationType) => {
        switch (communicationType) {
            case "llamada":
                return <PhoneIcon />;
            case "mensaje":
                return <ChatIcon />;
            default:
                return <MailIcon />;
        }
    };

    const onChange = (event) => {
        setData({ ...data, [event.target.name]: event.target.value })
    };

    const sendData = async () => {
        document.getElementById("preloader").style.display = "block";
        swal.fire({
            text: 'Cargando...',
            showConfirmButton: false,
            position: 'start',
            width: "10%",
            background: 'none',
            color: "white"
        })
        onUploadFiles(setFileslenght, setUploadFilesWithError, setUpLoadFileSuccess, files, setFilesMaxSize, idSiniestro);
        setInputDisabled({ ...data })
        const responseUpdateSiniestro = await putSiniestro({
            ...data,
            idSiniestro: idSiniestro
        });
        if (responseUpdateSiniestro.status === "success") {
            setIsUpdateSiniestro('success');
        } else {
            setIsUpdateSiniestro('error');
        }
    };

    const backToAllSiniestros = () => {
        window.location.assign(`/#/siniestros`)
    };

    return (
        <Grid>
            {dataSiniestro.siniestroNro?.numero ?
                <Grid>
                    <strong>Número de siniestro: </strong>
                    <span style={{ fontSize: "18px" }}>{dataSiniestro.siniestroNro?.numero}</span>
                </Grid>
                : ""}
            <Grid style={{ margin: "0.5% 0px 2.5%" }}>
                <strong>ID: </strong>
                <span className={classes.idSubtitle}>#{idSiniestro}</span>
            </Grid>
            <Grid className={classes.headerContainer}>
                <Grid className={classes.containerRight}>
                    <Grid style={{ fontWeight: 400 }}>
                        <strong>Tipo de siniestro: </strong>
                        {siniestroTipoSelected?.titulo}
                    </Grid>

                    <Grid className={classes.accordionContainer}>
                        <AccordionComponent dataOperacion={dataOperacion} />
                    </Grid>

                    <Grid className={classes.datesContainer}>
                        <strong>Fecha del siniestro: </strong>
                        <span>{data?.fechaSiniestro ? moment(data.fechaSiniestro).format("DD-MM-YYYY") : ""}</span>
                    </Grid>
                    <Grid className={classes.datesContainer}>
                        <strong>Fecha de denuncia: </strong>
                        <span>{data?.fechaDenuncia ? moment(data?.fechaDenuncia).format("DD-MM-YYYY") : ""}</span>
                    </Grid>
                    <Divider className={classes.divider} />
                </Grid>
                <Grid className={classes.containerLeft}>
                    <Grid style={{ fontWeight: 400 }}>
                        <strong>Tipo de estado: </strong>
                        {stateSiniestro?.titulo}
                    </Grid>
                    {stateSiniestro?.titulo === 'PAGADO' &&
                        <Grid className={classes.siniestroPagadoContainer}>
                            <strong>Fecha de pago: </strong>{moment(dataSiniestro.fechaPago).format("DD-MM-YYYY")} <br />
                            <strong>Monto liquidado: </strong> ${dataSiniestro.montoLiquidado}
                        </Grid>
                    }
                    <Paper
                        data-testid="sidebar"
                        square
                        className={classes.paper}
                        elevation={3}
                    >
                        {isEmpty(comunicationFormattedAndSorted) ? (
                            isLoading === 'waiting' ?
                                <Oval
                                    height={80}
                                    width={80}
                                    color="#1467a1"
                                    wrapperStyle={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%"
                                    }}
                                    secondaryColor="#1467a1"
                                /> :
                                nothingHere()) : (
                            <List className={classes.list}>
                                {comunicationFormattedAndSorted.map((comunicacion, index) => (
                                    <React.Fragment key={comunicacion.comunicacion_id || comunicacion.emailId}>
                                        {subheaderDateComparison(comunicacion.fecha, index)}
                                        <ListItem
                                            button
                                            onClick={() => { visualizeComunicacion(comunicacion.comunicacion_id) }}
                                        >
                                            <ListItemAvatar>
                                                <Avatar alt={comunicacion.titulo}>
                                                    {handleCommunicationType(comunicacion.titulo)}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <Typography variant="h5">
                                                {sliceText(comunicacion.asunto, 45)}
                                            </Typography>
                                        </ListItem>
                                    </React.Fragment>
                                ))}
                            </List>
                        )}
                    </Paper>
                </Grid>
            </Grid>
            <Grid style={{ fontSize: "22px" }}><span>Info de la denuncia</span></Grid>
            <Grid item md={12} className={classes.inputsContainer}>
                <span>Monto del reclamo</span>
                <TextField
                    type="number"
                    size="small"
                    id="outlined-basic"
                    name="montoReclamo"
                    variant="outlined"
                    onChange={onChange}
                    value={data.montoReclamo || 0}
                    InputProps={{
                        readOnly: dataSiniestro?.montoReclamo || inputDisabled.montoReclamo,
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item md={12} className={classes.inputsContainer}>
                <span>Transportista</span>
                <TextField
                    size="small"
                    id="outlined-basic"
                    name="transportista"
                    variant="outlined"
                    onChange={onChange}
                    value={data.transportista}
                    InputProps={{ readOnly: dataSiniestro?.transportista || inputDisabled.transportista }}
                />
            </Grid>
            <Grid item md={12} className={classes.inputsContainer}>
                <span>Quién lo contrata</span>
                <TextField
                    size="small"
                    id="outlined-basic"
                    name="contratador"
                    variant="outlined"
                    onChange={onChange}
                    value={data.contratador}
                    InputProps={{ readOnly: dataSiniestro?.contratador || inputDisabled.contratador }}

                />
            </Grid>
            <Grid item md={12} className={classes.inputsContainer}>
                <span>Lugar del siniestro</span>
                <TextField
                    size="small"
                    id="outlined-basic"
                    name="lugar"
                    variant="outlined"
                    onChange={onChange}
                    value={data.lugar}
                    InputProps={{ readOnly: dataSiniestro?.lugar || inputDisabled.lugar }}

                />
            </Grid>
            <Grid item md={12} className={classes.inputsContainer}>
                <span>Datos de inspección</span>
                <TextField
                    aria-label="minimum height"
                    minRows={4}
                    onChange={onChange}
                    value={data.datosInspeccion}
                    id="outlined-multiline-static"
                    multiline
                    name="datosInspeccion"
                    InputProps={{ readOnly: dataSiniestro?.datosInspeccion || inputDisabled.datosInspeccion }}
                />
            </Grid>
            <Grid item md={12} className={classes.inputsContainer}>
                <span>Descripción del hecho</span>
                <TextField
                    aria-label="minimum height"
                    minRows={6}
                    onChange={onChange}
                    value={data.descripcion}
                    id="outlined-multiline-static"
                    multiline
                    name="descripcion"
                    InputProps={{ readOnly: dataSiniestro?.descripcion || inputDisabled.descripcion }}
                />
            </Grid>
            <Grid style={{ width: "100%", marginTop: "5%" }}>
                <span style={{ fontWeight: "700" }}>Documentación subida</span>
                <List className={classes.listContainer}>
                    {!isEmpty(uploadedFiles) ?
                        uploadedFiles.map((file) => {
                            const filesFinded = fileTypes?.find((item) => item?.archivoTipo_id === file?.archivoTipo_id);
                            return (
                                <List className={classes.listItemsContainer}>
                                    <ListItem
                                        key={file.archivo_id}
                                        className={classes.listItem}
                                        dense
                                        button
                                    >
                                        <ListItemText
                                            primary={file?.nombre}
                                            className={classes.listItemText}
                                        />
                                        <ListItem className={classes.ListItemContainer}>
                                            {filesFinded ?
                                                <ListItemText primary={filesFinded?.titulo} /> :
                                                <ListItemText secondary={"Pendiente de revisión"}>
                                                </ListItemText>
                                            }
                                            <a
                                                href={file.url}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <IconButton>
                                                    <VisibilityIcon className={classes.icon} />
                                                </IconButton>
                                            </a>
                                        </ListItem>
                                    </ListItem>
                                </List>
                            )
                        }) :
                        nothingHere()
                    }
                </List>
                <span style={{ fontWeight: "700" }}>Subir nueva documentación</span>
                <Dropzone files={files} setFiles={setFiles} />
            </Grid>
            <Grid className={classes.containerButtons}>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => backToAllSiniestros()}
                >
                    Volver
                </Button>
                <Button
                    style={{ marginLeft: "2%" }}
                    color="primary"
                    variant="contained"
                    onClick={sendData}
                >
                    Enviar
                </Button>
            </Grid>
        </Grid>
    )
}

export default VisualizarSiniestro;