import React, { useCallback } from "react";
import map from "lodash/map";
import filter from "lodash/filter";
import forEach from "lodash/forEach";
import isEqual from "lodash/isEqual";
import { Card } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import nextId from "react-id-generator";
import fileIcon from "../../assets/file-icon.svg";
import CancelIcon from "@material-ui/icons/Cancel";
import BackupIcon from "@material-ui/icons/Backup";
import { DragNDropZoneStyled, FilesListStyled, FileListElementStyled, ImageFileStyled, useStyles } from "./styles";
import { Typography } from "@material-ui/core";
import clsx from "clsx";

function Dropzone(props) {
    const { files, setFiles, acceptedFormats, isLoading } = props;
    const classes = useStyles();
    const onDrop = useCallback(
        (acceptedFiles) => {
            forEach(acceptedFiles, (file) => (file["id"] = nextId()));
            setFiles([...files, ...acceptedFiles]);
        },
        [files, setFiles]
    );

    const usualFormats = ".jpeg,.png,.jpg,.pdf,.xlsx,.xls,.docx,.docm,.dotx,.xlsm,.xlsb,.txt"; //Agregar aca más formatos
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: acceptedFormats ? acceptedFormats : usualFormats,
    });

    const handleCancelClick = (fileToDelete) => () => {
        !isLoading &&
            setFiles(
                filter(files, (file) => !isEqual(fileToDelete.id, file.id))
            );
    };

    const zoneToDrop = (
        <DragNDropZoneStyled>
            <BackupIcon style={{ fontSize: 100 }} />
            <Card.Title>
                Arrastre y suelte algunos archivos aquí, o haga clic para
                seleccionar archivos
            </Card.Title>
        </DragNDropZoneStyled>
    );

    return (
        <div data-testid="dropzone">
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {zoneToDrop}
            </div>
            <FilesListStyled>
                {map(files, (file) => (
                    <FileListElementStyled key={file.name}>
                        <ImageFileStyled src={fileIcon} alt={file.name} />
                        <Typography variant="h5">{file.name}</Typography>
                        <CancelIcon
                            className={clsx(
                                classes.cancelIcon,
                                isLoading && classes.iconDisabled
                            )}
                            onClick={handleCancelClick(file)}
                        />
                    </FileListElementStyled>
                ))}
            </FilesListStyled>
        </div>
    );
}

export default Dropzone;
